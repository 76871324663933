<template>
  <div class="map-container" ref="mapDivRef"></div>
  <div v-if="loadingBaseNodes" class="map-loading">
    <i class="fa fa-spinner fa-spin me-1"></i> Loading
  </div>
  <button class="btn lock-zoom-btn" @click="resetAllValues" v-if="resetAll">
    Reset
  </button>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { createLabelOverlay } from "@/utils/LabelOverlay";

export default {
  name: "GoogleMap",
  props: {
    loadMapRadius: Boolean,
    baseNodeList: Object,
    remoteAddress: String,
    sectorResults: Object,
    multiSector: Boolean,
    clearRemoteNode: Boolean,
    loadBaseNodes: Boolean,
  },
  emits: [
    "update-lat-lng",
    "update-bn-radius",
    "reset-all",
    "update-base-node",
    "update-results-modal",
  ],
  setup(props, { emit }) {
    const mapDivRef = ref(null);
    const mapType = ref("hybrid");
    let map = null;
    let route = null;
    let multiSectorFlag = ref(false);
    let loadingBaseNodes = ref(false);
    let clusterClicked = ref(false);
    let lockZoom = ref(false);
    let resetAll = ref(false);
    let mapClickEvent = ref(false);
    let mapDragEvent = ref(false);
    let addressChanged = ref(false);
    let zoomChanged = ref(false);
    let clearAllMarkers = ref(true);
    let newLocation = null;
    let bnMarkerIcon = null;
    let rnMarkerIcon = null;
    let baseMarker = null;
    let remoteMarker = null;
    let infoWindow = null;
    let bnEncodedSVG = null;
    let rnEncodedSVG = null;
    let mapClickListener = null;
    let radiusCircle = null;
    let radiusRectangle = null;
    let markerClusterer = null;
    let selectedRN = null;
    let selectedBN = null;
    let labelOverlay = null;
    let baseNodeMarkers = [];
    let plottedBNMarkers = [];
    let polygonArray = [];
    let polylineArray = [];
    let labelMarkersArray = [];

    const toggleLockZoom = () => {
      lockZoom.value = !lockZoom.value;
      if (map) {
        if (lockZoom.value) {
          google.maps.event.clearListeners(map, "idle");
          google.maps.event.clearListeners(map, "zoom_changed");
          google.maps.event.clearListeners(map, "dragstart");
          google.maps.event.clearListeners(map, "dragend");
        } else {
          if (map.getZoom() > 12 && clusterClicked.value) {
            map.setZoom(12);
            zoomChanged.value = true;
            clusterClicked.value = false;
          }
          getMapCenterAndRadius();
          handleZoomDragforBN();
        }
      }
    };

    const setMapType = (type) => {
      if (map) {
        map.setMapTypeId(type);
        mapType.value = type; // Update active map type
      }
    };

    const resetAllValues = () => {
      // Clear previous MarkerClusterer and markers
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      if (radiusCircle) {
        radiusCircle.setMap(null);
      }

      if (radiusRectangle) {
        radiusRectangle.setMap(null);
      }

      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }

      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }

      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }

      if (polygonArray) {
        polygonArray.forEach((polygon) => polygon.setMap(null));
        polygonArray = [];
      }

      if (polylineArray) {
        polylineArray.forEach((polyline) => polyline.setMap(null));
        polylineArray = [];
      }

      if (labelMarkersArray) {
        labelMarkersArray.forEach((label) => label.setMap(null));
        labelMarkersArray = [];
      }

      if (plottedBNMarkers) {
        plottedBNMarkers.forEach((marker) => marker.setMap(null));
        plottedBNMarkers = [];
      }

      if (selectedRN) selectedRN = null;
      if (selectedBN) selectedBN = null;

      newLocation = null;
      lockZoom.value = false;
      resetAll.value = false;
      loadingBaseNodes.value = false;
      multiSectorFlag.value = false;
      addressChanged.value = false;
      mapClickEvent.value = false;
      mapDragEvent.value = false;
      clusterClicked.value = false;

      google.maps.event.clearListeners(map, "click");

      emit("reset-all", { remoteOnly: clearAllMarkers.value });

      // Re-add the map click listener
      mapClickListener = map.addListener("click", (event) => {
        mapClickHandler(event.latLng);
      });
    };

    // Define the callback function to initialize the map
    const initMap = () => {
      const options = {
        styles: [
          {
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      };

      const mapOptions = {
        center: { lat: 33.567395, lng: -97.02491 },
        zoom: 12,
        tilt: 0, // Ensure the map starts with no tilt
        disableDefaultUI: true,
        mapTypeId: "hybrid",
      };

      if (mapDivRef.value) {
        //const mapWidth = mapDivRef.value.offsetWidth;
        const mapHeight = window.innerHeight - 40;
        mapDivRef.value.style.height = mapHeight + "px";
      }

      map = new google.maps.Map(mapDivRef.value, mapOptions);
      infoWindow = new google.maps.InfoWindow();

      // Now that Google Maps is loaded, create the LabelOverlay class
      labelOverlay = createLabelOverlay();

      if (!lockZoom.value) {
        clusterClicked.value = false;
        getMapCenterAndRadius();
        handleZoomDragforBN();
      }

      bnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#025287",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#025287",
        strokeOpacity: 0.4,
      };

      rnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#c14d23",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#c14d23",
        strokeOpacity: 0.4,
      };

      // Attach the click event listener to the map
      mapClickListener = map.addListener("click", (event) => {
        mapClickHandler(event.latLng);
      });

      const bnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#025287" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      bnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(bnSVGIcon);

      const rnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#c14d23" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      rnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(rnSVGIcon);
    };

    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;

      // Set the initMap function to the window object to ensure it's accessible globally
      window.initMap = initMap;

      // Load the necessary libraries asynchronously
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Event listener for window resize
      window.addEventListener("resize", resizeMap);
    });

    // Watch for changes in props
    watch(
      [
        () => props.loadMapRadius,
        () => props.baseNodeList,
        () => props.remoteAddress,
        () => props.sectorResults,
        () => props.multiSector,
        () => props.clearRemoteNode,
        () => props.loadBaseNodes,
      ],
      (
        [
          loadMapRadius,
          baseNodeList,
          remoteAddress,
          sectorResults,
          multiSector,
          clearRemoteNode,
          loadBaseNodes,
        ],
        [
          prevLoadMapRadius,
          prevBaseNodeList,
          prevRemoteAddress,
          prevSectorResults,
          prevMultiSector,
          prevClearRemoteNode,
          prevLoadBaseNodes,
        ]
      ) => {
        if (!map) return; // Ensure map exists before proceeding

        loadingBaseNodes.value = loadBaseNodes;
        multiSectorFlag.value = multiSector; // Corrected spelling

        if (loadMapRadius) {
          getMapCenterAndRadius();
        }

        if (clearRemoteNode) {
          clearAllMarkers.value = false;
          resetAllValues();
        }

        if (baseNodeList.bn_list) {
          updateMapWithBaseNodes(baseNodeList);
        }

        if (remoteAddress) {
          if (remoteAddress !== prevRemoteAddress) {
            addressChanged.value = true;
            clusterClicked.value = false;
            zoomChanged.value = false;
            clearAllMarkers.value = true;
          }
          geocodeAddress(remoteAddress);
        }

        // Check if sectorResults is an empty object before updating the map
        if (sectorResults) {
          updateMap(sectorResults);
        }
      },
      { deep: true }
    );

    const handleZoomDragforBN = () => {
      let zooming = false; // Flag to track zooming activity
      let dragging = false; // Flag to track dragging activity
      // Detect when zooming starts
      map.addListener("zoom_changed", () => {
        zooming = true;
      });

      // Detect when dragging starts
      map.addListener("dragstart", () => {
        dragging = true;
      });

      // Detect when dragging ends
      map.addListener("dragend", () => {
        dragging = true; // Mark dragging as started
      });

      // Detect when zooming stops (idle event fires after movement completes)
      map.addListener("idle", () => {
        if (zooming) {
          zooming = false;
          clusterClicked.value = false;
          setTimeout(() => {
            getMapCenterAndRadius();
          }, 1500); // Delay execution by 1.5 seconds after zoom stops
        }

        if (dragging) {
          dragging = false;
          clusterClicked.value = false;
          setTimeout(() => {
            getMapCenterAndRadius();
          }, 1500); // Delay execution by 1.5 second after dragging stops
        }
      });
    };

    // Function to handle map clicks and plot RN marker
    const mapClickHandler = (location) => {
      let rnSvgMarkerIcon = {
        url: rnEncodedSVG, // Use the encoded SVG Data URL
        scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
        anchor: new google.maps.Point(20, 40),
      };

      // Remove existing RN marker, if any
      if (remoteMarker) {
        remoteMarker.setMap(null);
        remoteMarker = null;
      }

      // Create a new marker at the clicked location
      remoteMarker = new google.maps.Marker({
        position: location,
        icon: rnSvgMarkerIcon,
        map,
        title: "RN Marker",
      });

      const clickedLatLng = {
        lat: location.lat(),
        lng: location.lng(),
      };

      mapClickEvent.value = true;
      clearAllMarkers.value = true;
      newLocation = clickedLatLng;
      updateLocationInfo(clickedLatLng);
    };

    const getMapCenterAndRadius = () => {
      if (!map) return;

      // Get the bounds of the visible map area
      const bounds = map.getBounds();
      const center = map.getCenter();

      if (bounds && center) {
        // Center coordinates
        const centerLat = center.lat();
        const centerLng = center.lng();

        zoomChanged.value = true;

        const radius = calculateRadiusFromBounds();

        console.log("Center Latitude:", centerLat);
        console.log("Center Longitude:", centerLng);
        console.log("Radius (meters):", radius);

        if (!clusterClicked.value && !lockZoom.value) {
          // Emit the center and radius info
          emit("update-bn-radius", {
            latitude: centerLat,
            longitude: centerLng,
            radius,
          });
        }
      }
    };

    const calculateRadiusFromBounds = () => {
      // Calculate the rectangle bounds
      const bounds = map.getBounds();

      const boundsRect = {
        north: bounds.getNorthEast().lat(),
        east: bounds.getNorthEast().lng(),
        south: bounds.getSouthWest().lat(),
        west: bounds.getSouthWest().lng(),
      };
      const toRadians = (degrees) => (degrees * Math.PI) / 180;

      const earthRadius = 6371000; // Earth's radius in meters

      // Extract bounds coordinates
      const { north, east, south, west } = boundsRect;

      // Calculate the center of the bounds
      const centerLat = (north + south) / 2;
      const centerLng = (east + west) / 2;

      // Function to calculate distance using Haversine formula
      const haversineDistance = (lat1, lng1, lat2, lng2) => {
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadius * c;
      };

      // Calculate distances to each corner of the bounds
      const distances = [
        haversineDistance(centerLat, centerLng, north, east), // NE corner
        haversineDistance(centerLat, centerLng, north, west), // NW corner
        haversineDistance(centerLat, centerLng, south, east), // SE corner
        haversineDistance(centerLat, centerLng, south, west), // SW corner
      ];

      // Get the maximum distance as the effective radius
      const radius = Math.max(...distances);

      console.log(
        `Radius from center to farthest corner: ${radius.toFixed(2)} meters`
      );
      return radius;
    };

    const geocodeAddress = (address) => {
      if (addressChanged.value && !mapClickEvent.value && !mapDragEvent.value) {
        newLocation = null;
      }
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          let latLng = {
            lat: location.lat(),
            lng: location.lng(),
          };
          if (newLocation) {
            latLng = newLocation;
          }
          if (!newLocation && clearAllMarkers.value) {
            emit("update-lat-lng", {
              coordinates: latLng,
            });
          }
          placeRemoteNodeMarker(latLng);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    };

    const getAddressFromLatLng = (latLng, callback) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address;
            callback(address);
          } else {
            console.log("No results found");
            callback(null);
          }
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    };

    const placeRemoteNodeMarker = (location, bnExist = false) => {
      let rnSvgMarkerIcon = {
        url: rnEncodedSVG, // Use the encoded SVG Data URL
        scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
        anchor: new google.maps.Point(20, 40),
      };

      let remoteMarkerIcon = rnSvgMarkerIcon;
      if (bnExist) {
        remoteMarkerIcon = rnMarkerIcon;
      }

      if (!remoteMarker && clearAllMarkers.value) {
        remoteMarker = new google.maps.Marker({
          position: location,
          icon: remoteMarkerIcon,
          map,
          draggable: false,
          title: "RN Marker",
        });

        /* remoteMarker.addListener("dragend", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          newLocation = newLatLng;
          mapDragEvent.value = true;
          updateLocationInfo(newLatLng);
          moveToLocation(newLocation);
        }); */

        remoteMarker.addListener("click", (event, newLocation) => {
          let newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          if (newLocation) newLatLng = newLocation;
          showInfoWindow(newLatLng, remoteMarker);
        });

        if (!mapClickEvent.value && !zoomChanged.value) {
          moveToLocation(location);
        }

        if (
          addressChanged.value &&
          !mapClickEvent.value &&
          !mapDragEvent.value
        ) {
          getMapCenterAndRadius();
        }

        addressChanged.value = false;
        mapClickEvent.value = false;
        mapDragEvent.value = false;
      }
    };

    const moveToLocation = (location) => {
      const currentCenter = map.getCenter();

      // Check if the new position is different from the current center
      if (
        currentCenter.lat() !== location.lat ||
        currentCenter.lng() !== location.lng
      ) {
        // If different, pan the map to the new position
        map.panTo(new google.maps.LatLng(location.lat, location.lng));
      }
    };

    const updateLocationInfo = (latLng) => {
      getAddressFromLatLng(latLng, (address) => {
        emit("update-lat-lng", {
          coordinates: latLng,
          address,
          clicked: mapClickEvent.value,
        });
      });
    };

    const showBaseNodeInfoWindow = (node, baseNodeMarker) => {
      const contentString = `
        <div class="info-window">
          <h3>BN Information</h3>
          <table class="table">
            <tr>
              <td class="fw-bold">Operator Name</td>
              <td>${node.operator}</td>
            </tr>
            <tr>
              <td class="fw-bold">Region Name</td>
              <td>${node.region}</td>
            </tr>
            <tr>
              <td class="fw-bold">Market Name</td>
              <td>${node.market}</td>
            </tr>
            <tr>
              <td class="fw-bold">Site Name</td>
              <td>${node.site}</td>
            </tr>
            <tr>
              <td class="fw-bold">Cell Name</td>
              <td>${node.cell}</td>
            </tr>
            <tr>
              <td class="fw-bold">Sector Name</td>
              <td>${node.sector}</td>
            </tr>
            <tr>
              <td class="fw-bold">Serial Number</td>
              <td>${node.serial}</td>
            </tr>
            <tr>
              <td class="fw-bold">Latest Timestamp</td>
              <td>${formatDate(node.timestamp)}</td>
            </tr>
            <tr>
              <td class="fw-bold">Firmware Version</td>
              <td>${node.firmware}</td>
            </tr>
            <tr>
              <td class="fw-bold">Subscriber Count</td>
              <td>${node.subscribers}</td>
            </tr>
            <tr>
              <td class="fw-bold">Latitude</td>
              <td>${node.latitude.toFixed(6)}</td>
            </tr>
            <tr>
              <td class="fw-bold">Longitude</td>
              <td>${node.longitude.toFixed(6)}</td>
            </tr>
            <tr>
              <td class="fw-bold">Height (AGL)</td>
              <td>${
                node.height_agl ? `${node.height_agl.toFixed(2)} meters` : ""
              }</td>
            </tr>
            <tr>
              <td class="fw-bold">Azimuth</td>
              <td>${
                node.azimuth
                  ? `${node.azimuth.toFixed(2)} degrees (cw from north)`
                  : ""
              }</td>
            </tr>
            <tr>
              <td class="fw-bold">Downtilt</td>
              <td>${
                node.downtilt ? `${node.downtilt.toFixed(2)} degrees` : ""
              }</td>
            </tr>
            <tr>
              <td class="fw-bold">Carrier Frequencies</td>
              <td>${formatValues("frequency", node.carrier)}</td>
            </tr>
            <tr>
              <td class="fw-bold">Carrier Bandwidths</td>
              <td>${formatValues("bandwidth", node.carrier)}</td>
            </tr>
            <tr>
              <td class="fw-bold">Carrier TX Powers</td>
              <td>${formatValues("tx_power", node.carrier)}</td>
            </tr>
          </table>
        </div>
      `;

      if (infoWindow) infoWindow.close();
      infoWindow.setContent(contentString);
      infoWindow.open(map, baseNodeMarker);

      emit("update-base-node", node);
    };

    const showInfoWindow = (latLng, marker) => {
      getAddressFromLatLng(latLng, (address) => {
        let newLatitude = latLng.lat.toFixed(6);
        let newLongitude = latLng.lng.toFixed(6);
        const contentString = `
        <div class="info-window">
          <h3>Location Info</h3>
          <table class="table">
            <tr>
              <td class="fw-bold">Address</td>
              <td>${address || "N/A"}</td>
            </tr>
            <tr>
              <td class="fw-bold">Latitude</td>
              <td>${newLatitude}</td>
            </tr>
            <tr>
              <td class="fw-bold">Longitude</td>
              <td>${newLongitude}</td>
            </tr>
          </table>
        </div>
      `;

        if (infoWindow) infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
      });
    };

    const calculateCenterPoint = (nodes) => {
      const totalNodes = nodes.length;
      const sumLat = nodes.reduce((sum, node) => sum + node.latitude, 0);
      const sumLng = nodes.reduce((sum, node) => sum + node.longitude, 0);

      return {
        lat: sumLat / totalNodes,
        lng: sumLng / totalNodes,
      };
    };

    const updateMapWithBaseNodes = (baseNodeList) => {
      /* let bnMarkerIcon = {
        url: bnEncodedSVG,
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 40),
      }; */

      clusterClicked.value = false;

      // Define MarkerClusterer options
      const clusterOptions = {
        minimumClusterSize: 2,
        maxZoom: 15,
      };

      // Clear previous MarkerClusterer and markers
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      baseNodeMarkers.forEach((marker) => marker.setMap(null));
      baseNodeMarkers = [];

      polygonArray.forEach((polygon) => polygon.setMap(null));
      polygonArray = [];

      if (radiusCircle) {
        radiusCircle.setMap(null);
      }

      if (radiusRectangle) {
        radiusRectangle.setMap(null);
      }

      if (!baseNodeList.showBNRadius) {
        return;
      }

      const usedCoordinates = new Set();

      if (baseNodeList.bn_list.length > 0) {
        let baseNodeRadius = parseInt(baseNodeList.radius);
        let centerPoint = calculateCenterPoint(baseNodeList.bn_list);

        if (!baseNodeList.rectangleAPICall) {
          radiusCircle = new google.maps.Circle({
            strokeColor: "#4285F4",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: "#4285F4",
            fillOpacity: 0.1,
            map,
            center: centerPoint,
            radius: baseNodeRadius,
          });
        }

        baseNodeList.bn_list.forEach((node) => {
          let lat = node.latitude;
          let lng = node.longitude;

          let coordinateKey = `${lat},${lng}`;

          // Apply offset if coordinate already exists
          while (usedCoordinates.has(coordinateKey)) {
            lat += Math.random() * 0.0001 - 0.00005; // Slight offset
            lng += Math.random() * 0.0001 - 0.00005;
            coordinateKey = `${lat},${lng}`;
          }

          usedCoordinates.add(coordinateKey);

          if (node.azimuth !== null && node.azimuth !== undefined) {
            const azimuthAngle = (node.azimuth * Math.PI) / 180; // Convert azimuth to radians
            const spreadAngle = 40 * (Math.PI / 180); // ±40° spread angle (converted to radians)
            const distance = 0.01; // Distance for the polygon edges (adjust based on zoom level)
            const steps = 50; // Number of steps to create a smooth curve
            const arcPoints = [];

            // Generate arc points along the curve (top of the arc)
            for (let i = 0; i <= steps; i++) {
              const angle =
                azimuthAngle - spreadAngle + (i * 2 * spreadAngle) / steps; // Calculate each angle in the arc

              // Calculate latitude and longitude offsets using spherical geometry
              const latOffset = distance * Math.cos(angle); // Latitude offset based on the angle
              const lngOffset = distance * Math.sin(angle); // Longitude offset based on the angle

              // Correct longitude for Earth's curvature by dividing by the cosine of latitude
              arcPoints.push({
                lat: lat + latOffset, // Latitude change
                lng: lng + lngOffset / Math.cos((lat * Math.PI) / 180), // Longitude adjustment for curvature
              });
            }

            // Construct the polygon path for the sector, including the arc and arrow
            const polygonPath = [
              { lat, lng }, // Start point (center of the sector)
              ...arcPoints, // Arc points along the curve
              { lat, lng }, // Close the polygon by going back to the center (this ensures closure)
            ];

            // Draw the sector as a filled polygon
            const azimuthPolygon = new google.maps.Polygon({
              paths: polygonPath, // Use the constructed path
              strokeColor: "#438973", // Outline color
              strokeOpacity: 1, // Outline opacity
              strokeWeight: 2, // Outline thickness
              fillColor: "#5ebc8b", // Fill color
              fillOpacity: 0.4, // Fill opacity
              map, // Add to the map
              clickable: false,
            });

            // Add the polygon to the array for later removal
            polygonArray.push(azimuthPolygon);
            //polylineArray.push(azimuthPolyline);
          }

          const baseNodeMarker = new google.maps.Marker({
            position: { lat, lng },
            icon: bnMarkerIcon,
            title: "BN Marker",
          });

          baseNodeMarker.addListener("click", () => {
            showBaseNodeInfoWindow(node, baseNodeMarker);
          });

          baseNodeMarkers.push(baseNodeMarker);
        });

        //map.setZoom(zoomLevel);
      }

      markerClusterer = new MarkerClusterer({
        markers: baseNodeMarkers,
        map: map,
        ...clusterOptions,
      });

      // Listen for cluster click
      markerClusterer.addListener("click", (event) => {
        clusterClicked.value = true;
      });
    };

    const formatDate = (timestamp) => {
      if (timestamp) {
        let date = new Date(timestamp);
        const formattedDate =
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getDate()).padStart(2, "0");
        return formattedDate;
      }
      return "";
    };

    const getMidpoint = (baseNode, remoteNode) => {
      let latMid = (baseNode.lat() + remoteNode.lat()) / 2;
      let lngMid = (baseNode.lng() + remoteNode.lng()) / 2;

      const SMALL_OFFSET = 0.0005;

      latMid += (Math.random() - 0.5) * SMALL_OFFSET; // Adjust latitude slightly
      lngMid += (Math.random() - 0.5) * SMALL_OFFSET; // Adjust longitude slightly

      return { lat: latMid, lng: lngMid };
    };

    // Helper function to extract and format values
    const formatValues = (key, data) => {
      if (data.length > 0) {
        let carrier = data
          .filter((item) => item[key] !== 0) // Exclude entries with zero values
          .map((item) => item[key])
          .join(", ");
        carrier += carrier ? (key == "tx_power" ? " dB" : " MHz") : "";
        return carrier;
      }
      return "";
    };

    const drawSingleSectorPolyline = (baseNode, remoteNode) => {
      const baseLatLng = new google.maps.LatLng(baseNode.lat, baseNode.lng);
      const remoteLatLng = new google.maps.LatLng(
        remoteNode.lat,
        remoteNode.lng
      );
      // Define bounds to encompass both base and remote nodes
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(baseLatLng);
      bounds.extend(remoteLatLng);

      // Add extra padding to the bounds (e.g., upward)
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const verticalPadding = 0.1; // Adjust this value for more/less padding
      const adjustedBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          southWest.lat() - verticalPadding,
          southWest.lng()
        ),
        new google.maps.LatLng(
          northEast.lat() + verticalPadding,
          northEast.lng()
        )
      );

      // Fit the map to these adjusted bounds
      map.fitBounds(adjustedBounds);

      // Calculate distance between base and remote nodes
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        baseLatLng,
        remoteLatLng
      );

      // Adjust zoom level based on distance
      let zoomLevel = 12; // Default zoom level
      if (distance < 1000) {
        zoomLevel = 14; // Adjust zoom level for closer distances
      } else if (distance > 10000) {
        zoomLevel = 12; // Adjust zoom level for closer distances
      } else if (distance > 100000) {
        zoomLevel = 10; // Adjust zoom level for closer distances
      } else if (distance > 1000000) {
        zoomLevel = 8; // Adjust zoom level for larger distances
      } else if (distance > 10000000) {
        zoomLevel = 6;
      }

      // Set the map's zoom to the calculated max zoom level if it exceeds it
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        if (map.getZoom() > zoomLevel) {
          map.setZoom(zoomLevel);
        }
      });

      const lineSymbol = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 0.8,
      };

      // Draw a polyline between the two points
      route = new google.maps.Polyline({
        path: [baseLatLng, remoteLatLng],
        geodesic: true,
        strokeColor: "#000000",
        strokeOpacity: 0.2,
        strokeWeight: 2,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "3px",
          },
        ],
      });

      // Set polyline on the map
      route.setMap(map);
      emit("update-results-modal", {
        remote_data: selectedRN,
        line_click: false,
      });
      emit("update-base-node", selectedBN);

      repositionMapPoints();
    };

    const selectBestHeight = (remoteNodes) => {
      let heightOptions = [];
      remoteNodes.forEach((remote, index) => {
        const height = remote?.rn?.heights?.above_open ?? 0;
        const LCC = remote?.aiml_output?.link_close_confidence ?? 0;

        if (LCC >= 0.25) {
          heightOptions.push({ index, height, LCC, remote });
          // Sort by LCC (descending) and height (ascending)
          heightOptions.sort((a, b) => {
            if (b.LCC !== a.LCC) return b.LCC - a.LCC; // Higher LCC first
            return a.height - b.height; // Lower height first if LCC is the same
          });
        }
      });

      // Return the best height option
      return heightOptions.length > 0 ? heightOptions[0] : null;
    };

    const drawMultiSectorPolyline = (baseNodes, remoteNodes) => {
      if (remoteNodes.length === 0 || baseNodes.length === 0) return;

      // Define remoteNode
      const remoteNode = {
        lat: parseFloat(remoteNodes[0].rn.latitude),
        lng: parseFloat(remoteNodes[0].rn.longitude),
      };

      const remoteLatLng = new google.maps.LatLng(
        remoteNode.lat,
        remoteNode.lng
      );

      // Define bounds to encompass both base and remote nodes
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(remoteLatLng);

      // Add extra padding to the bounds (e.g., upward)
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const verticalPadding = 0.1; // Adjust this value for more/less padding
      const adjustedBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          southWest.lat() - verticalPadding,
          southWest.lng()
        ),
        new google.maps.LatLng(
          northEast.lat() + verticalPadding,
          northEast.lng()
        )
      );

      // Fit the map to these adjusted bounds
      map.fitBounds(adjustedBounds);

      const bestHeightData = selectBestHeight(remoteNodes);

      //let highestLCC = 0;
      let selectedBNDistance = Infinity;
      let selectedRemoteNode = null;
      let selectedBaseNode = null;

      baseNodes.forEach((bn, index) => {
        if (bn.latitude && bn.longitude) {
          let baseNode = {
            lat: parseFloat(bn.latitude),
            lng: parseFloat(bn.longitude),
          };

          const baseLatLng = new google.maps.LatLng(baseNode.lat, baseNode.lng);

          // Calculate distance
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              baseLatLng,
              remoteLatLng
            );

          const distanceText = `${(distance / 1000).toFixed(2)} km`;

          // Adjust zoom level based on distance
          let zoomLevel = 12;
          if (distance < 1000) zoomLevel = 14;
          else if (distance > 10000) zoomLevel = 12;
          else if (distance > 100000) zoomLevel = 10;
          else if (distance > 1000000) zoomLevel = 8;
          else if (distance > 10000000) zoomLevel = 6;

          // Set the map's zoom level
          google.maps.event.addListenerOnce(map, "bounds_changed", () => {
            if (map.getZoom() > zoomLevel) {
              map.setZoom(zoomLevel);
            }
          });

          const heightOpen = remoteNodes[index]?.rn?.heights?.above_open ?? 0;
          //const heightRooftop = remoteNodes[index]?.rn?.heights?.above_roof ?? 0;
          const heightText = "Height: " + heightOpen + "m (Open)";

          const LCC =
            remoteNodes[index]?.aiml_output?.link_close_confidence ?? 0;
          const PL =
            remoteNodes[index]?.aiml_output?.path_loss?.average ?? "N/A";

          // Determine line color based on LCC
          let strokeColor;

          if (LCC > 0.75) {
            strokeColor = "#008000"; // Green for 0.76 and above
          } else if (LCC > 0.5) {
            strokeColor = "#FFFF00"; // Yellow for 0.51 - 0.75
          } else if (LCC > 0.25) {
            strokeColor = "#FF0000"; // Red for 0.26 - 0.50
          } else {
            return; // Skip plotting for LCC ≤ 0.25
          }

          // Determine if this BN should be selected for the modal
          if (bestHeightData) {
            selectedRemoteNode = remoteNodes[bestHeightData.index];
            selectedBaseNode = bn;
            selectedBNDistance = distance;
          }

          // Draw a polyline with visible dashed symbols
          const polyline = new google.maps.Polyline({
            path: [baseLatLng, remoteLatLng],
            geodesic: true,
            strokeWeight: 2,
            strokeColor: strokeColor,
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map,
            clickable: true,
          });

          polyline.addListener("click", () => {
            selectedRN = remoteNodes[index];
            selectedBN = bn;
            emit("update-results-modal", {
              remote_data: selectedRN,
              line_click: true,
            });
            emit("update-base-node", selectedBN);
          });

          const midpoint = getMidpoint(baseLatLng, remoteLatLng);
          const mapLabel =
            distanceText +
            " | LCC: " +
            (LCC * 100).toFixed(0) +
            "%" +
            " | PL: " +
            PL +
            " | " +
            heightText;

          const labelMarker = new labelOverlay(midpoint, mapLabel, map, () => {
            selectedRN = remoteNodes[index];
            selectedBN = bn;
            emit("update-results-modal", {
              remote_data: selectedRN,
              line_click: true,
            });
            emit("update-base-node", selectedBN);
          });

          // Store the polyline in the array and add to the map
          polylineArray.push(polyline);
          labelMarkersArray.push(labelMarker);
        }
      });

      if (selectedRN) selectedRemoteNode = selectedRN;
      if (selectedBN) selectedBaseNode = selectedBN;

      // If both RN and BN are selected, show its info in the modal and navigation
      if (selectedRemoteNode && selectedBaseNode) {
        emit("update-results-modal", {
          remote_data: selectedRemoteNode,
          line_click: false,
        });
        emit("update-base-node", selectedBaseNode);
      } else {
        emit("update-results-modal");
      }
      repositionMapPoints();
    };

    const repositionMapPoints = () => {
      resetAll.value = true;
      clearAllMarkers.value = true;
      lockZoom.value = true;

      // Reposition the map center slightly upward to move the polyline toward the top
      google.maps.event.addListenerOnce(map, "idle", () => {
        const center = map.getCenter();
        const mapHeightOffset = 0.1; // Adjust this value to control how much the map shifts
        const adjustedLatLng = {
          lat: center.lat() - mapHeightOffset,
          lng: center.lng(),
        };
        moveToLocation(adjustedLatLng);
      });
      // Clear the map click event listener
      if (mapClickListener) {
        google.maps.event.removeListener(mapClickListener);
        mapClickListener = null; // Clear the reference
      }
    };

    const updateMap = (sectorResults) => {
      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }

      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }

      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }

      if (polylineArray.length > 0) {
        polylineArray.forEach((polyline) => polyline.setMap(null));
        polylineArray = [];
      }

      if (labelMarkersArray.length > 0) {
        labelMarkersArray.forEach((label) => label.setMap(null));
        labelMarkersArray = [];
      }

      if (plottedBNMarkers.length > 0) {
        plottedBNMarkers.forEach((marker) => marker.setMap(null));
        plottedBNMarkers = [];
      }

      if (sectorResults.sector_list || sectorResults.bn) {
        let baseNodeExist = false;
        let remoteNodeExist = false;

        let remoteNode = {};
        let baseNode = {};
        let remoteNodes = [];
        let baseNodes = [];

        if (!multiSectorFlag.value) {
          if (sectorResults.bn) {
            baseNode.lat = sectorResults.bn.latitude
              ? parseFloat(sectorResults.bn.latitude)
              : "";
            baseNode.lng = sectorResults.bn.longitude
              ? parseFloat(sectorResults.bn.longitude)
              : "";
            const baseLatLng = new google.maps.LatLng(
              baseNode.lat,
              baseNode.lng
            );

            if (baseNode.lat != "" && baseNode.lng != "") {
              baseMarker = new google.maps.Marker({
                position: baseLatLng,
                map,
                icon: bnMarkerIcon,
                title: "BN Marker",
              });

              baseMarker.addListener("click", () => {
                showBaseNodeInfoWindow(sectorResults.bn, baseMarker);
              });

              moveToLocation(baseNode);
              baseNodeExist = true;
              selectedBN = sectorResults.bn;
            }
          }
          if (sectorResults.rn_list) {
            remoteNode.lat = parseFloat(sectorResults.rn_list[0].rn.latitude);
            remoteNode.lng = parseFloat(sectorResults.rn_list[0].rn.longitude);

            if (remoteNode.lat != "" && remoteNode.lng != "") {
              selectedRN = sectorResults.rn_list[0];
              placeRemoteNodeMarker(remoteNode, baseNodeExist);
              remoteNodeExist = true;
            }
          }
          if (baseNodeExist && remoteNodeExist) {
            drawSingleSectorPolyline(baseNode, remoteNode);
          }
        } else {
          if (sectorResults.sector_list) {
            // Loop through each sector in sector_list
            sectorResults.sector_list.forEach((sector) => {
              if (sector.bn && Object.keys(sector.bn).length > 0) {
                Object.values(sector).forEach((bn) => {
                  if (bn.latitude && bn.longitude) {
                    baseNodes.push(bn);

                    const baseLatLng = {
                      lat: parseFloat(bn.latitude),
                      lng: parseFloat(bn.longitude),
                    };

                    if (!isNaN(baseLatLng.lat) && !isNaN(baseLatLng.lng)) {
                      const bnMarker = new google.maps.Marker({
                        position: baseLatLng,
                        map,
                        icon: bnMarkerIcon,
                        title: "BN Marker",
                      });

                      bnMarker.addListener("click", () => {
                        showBaseNodeInfoWindow(bn, bnMarker);
                      });

                      moveToLocation(baseLatLng);
                      plottedBNMarkers.push(bnMarker);
                    }
                  }
                });
              }

              // Store all rn_list (Relay Nodes)
              if (sector.rn_list && sector.rn_list.length > 0) {
                const bestHeightData = selectBestHeight(sector.rn_list);
                if (bestHeightData) {
                  remoteNodes.push(bestHeightData.remote);
                }
              }
            });

            if (baseNodes.length > 0) {
              baseNodeExist = true;
            }

            if (remoteNodes.length == 0) {
              remoteNodes.push(sectorResults.sector_list[0]?.rn_list[0]);
            }

            if (remoteNodes.length > 0) {
              remoteNode.lat = parseFloat(remoteNodes[0].rn.latitude);
              remoteNode.lng = parseFloat(remoteNodes[0].rn.longitude);

              if (remoteNode.lat != "" && remoteNode.lng != "") {
                placeRemoteNodeMarker(remoteNode, baseNodeExist);
                remoteNodeExist = true;
              }
            }
            if (baseNodeExist && remoteNodeExist) {
              drawMultiSectorPolyline(baseNodes, remoteNodes);
            }
          }
        }
      }
    };

    const resizeMap = () => {
      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
        google.maps.event.clearListeners(map, "zoom_changed");
        google.maps.event.clearListeners(map, "idle");
      }
    };

    return {
      mapDivRef,
      mapType,
      lockZoom,
      resetAll,
      resetAllValues,
      loadingBaseNodes,
      toggleLockZoom,
      handleZoomDragforBN,
      setMapType,
    };
  },
};
</script>
