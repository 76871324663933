export function createLabelOverlay() {
  if (!window.google || !window.google.maps) {
    throw new Error("Google Maps API is not loaded yet.");
  }

  return class LabelOverlay extends google.maps.OverlayView {
    constructor(position, text, map, onClick) {
      super();
      this.position = position;
      this.text = text;
      this.map = map;
      this.onClick = onClick;
      this.div = null;
      this.setMap(map);
    }

    onAdd() {
      this.div = document.createElement("div");
      this.div.className = "map-label";
      this.div.innerHTML = this.text;

      // **Fix: Ensure Click Event Works**
      this.div.addEventListener("click", (event) => {
        event.stopPropagation(); // Prevents event bubbling
        if (this.onClick) this.onClick();
      });

      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(this.div); // Ensure interaction is possible
    }

    draw() {
      if (!this.div) return;
      const overlayProjection = this.getProjection();
      const position = overlayProjection.fromLatLngToDivPixel(this.position);
      this.div.style.left = `${position.x - 120}px`;
      this.div.style.top = `${position.y}px`;
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  };
}
